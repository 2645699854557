<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-dialog
        v-model="dialog"
        persistent
        :max-width="1200"
    >
      <v-card class="pa-4 rounded-xl">
        <v-card-title>
          <h3 class="text-h5">{{ title }}</h3>
        </v-card-title>
        <v-card-title>
          <h4 class="text-h6">Vorlage wählen</h4>
        </v-card-title>
        <v-card-text>
          <v-form ref="formular">
            <v-container>
              <v-row>
                <v-col cols="12" md="4" v-for="entry of entries" :key="entry.id">
                  <v-card class="pb-3 rounded-xl pt-3"  min-height="200px" style="background-color: #F3F4F3" flat>
                    <v-row>
                      <v-col cols="8">
                        <v-card-title>
                          <h5>{{ entry.name }}<a><v-icon @click="deleteTemplate(entry.id)">mdi-delete</v-icon></a></h5><!--<h6 class="grey--text ms-1">Bald verfügbar</h6>-->
                        </v-card-title>
                        <v-card-text>
                          {{ entry.description }}<br> <br>
                          <a @click="withTemplate(entry.id)"><span class="primary--text text-subtitle-1" >ERSTELLEN</span></a>
                        </v-card-text>

                      </v-col>
                      <v-col cols="4" class="align-self-center">
                        <v-icon size="77">mdi mdi-file-document</v-icon>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card class="pb-3 rounded-xl pt-3 grey white--text" min-height="200px" flat>
                    <v-row>
                      <v-col cols="8">
                        <v-card-title>
                          <h5>Neue Vorlagen</h5><!--<h6 class="grey--text ms-1">Bald verfügbar</h6>-->
                        </v-card-title>
                        <v-card-text>
                          Speichere deine erstellen Pläne als Vorlage, damit sie hier erscheinen.
                        </v-card-text>
                      </v-col>
                      <v-col cols="4" class="align-self-center">
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card style="background-color: #F3F4F3" @click="withoutTemplate()" flat
                          class="text-center pa-6 rounded-xl"><h4 class="text--h4">Ohne Vorlage starten</h4></v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="resetForm()"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

const Message = () => import("@/components/generalUI/Message");
import {error, msgObj, success} from "@/helper/helper";

/**
 * Dialog to edit business information depending on category of vendor
 * @displayName Dialog
 */
export default {
  name: "Vorlagen",
  components: {
    Message
  },
  props: {
    /**
     * Contains if the dialog is visible or not
     * @values true, false
     */
    dialog: {
      type: Boolean,
      default: false
    },
    title: String,
    entries: Array,
    type: String
  },

  data() {
    return {
      contact: {},
      stepper: 1,
      valid: false,
      message: msgObj()
    }
  },
  computed: {
    newValues() {
      return this.values
    },
  },
  methods: {
    /**
     * Stops editing the business information
     * @public
     */
    resetForm() {
      /**
       * Emits to hide the dialog
       */
      this.$emit('hideDialog')
    },
    withTemplate(templateId) {
      this.$emit('withTemplate', templateId)

    },
    withoutTemplate() {
      this.$emit('withoutTemplate')
    },
    deleteTemplate(templateId){
      this.message = success('Vorlage erfoglreich gelöscht.')
      if(this.type === 'Budgetplan'){
        this.$store.dispatch('budgetPlan/deleteTemplate', {
          templateId: templateId
        }).catch((err) => {
          this.message = error(err)
        })
      }else if(this.type === 'Tagesablauf'){
        this.$store.dispatch('timePlan/deleteTemplate', {
          templateId: templateId
        }).catch((err) => {
          this.message = error(err)
        })
      }
      setTimeout(()=>{
        this.$emit('deletedTemplate')
      }, 1500)
    }
  }
}
</script>

<style scoped>

</style>
